import React, { Component } from 'react';
import _ from 'lodash';
import { CameraListInit } from './constant'

import { firebase } from '../../firebase';
import * as fire from 'firebase';


const CameraList = CameraListInit;

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            DBcamList: {}
        };




        const GetCamlistDB = fire.database().ref('Camlist/');
        GetCamlistDB.on('value', (snapshot) => {
            const CameraList = snapshot.val();
            this.setState({ DBcamList: CameraList });
        });

        console.log(this.state);

    }


    render() {

        let list = _.map(this.state.DBcamList,
            (item, index) => {
                return <option
                    id={item.Resolution}
                    key={index}>{item.name}
                </option>
            });

        return (

            <div>
                <div className="form-group row container-fluid pb-4">
                    <span className='control-label'>Camera</span>
                    <select required
                        value={this.props.select}
                        name="select"
                        onChange={(item) => this.props.onChange(item)}
                        className="form-control" >
                        {list}
                    </select>
                    <span className='control-label mb-0'>Lens</span>
                    <input required
                        value={this.props.Lens}
                        name="Lens"
                        onChange={(item) => this.props.onChange(item)}
                        className="form-control mt-0"
                        type="number"
                        inputMode='decimal'
                        pattern='[0-9].,*'
                        placeholder="Lens Focal Length" />
                </div>

                <div className="form-group row container-fluid pb-4">
                    <span className='control-label mb-0'>Field of View</span>
                    <input required
                        value={this.props.Fov}
                        name="Fov"
                        readOnly={this.props.Read}
                        onChange={(item) => this.props.onChange(item)}
                        className="form-control"
                        type="number"
                        inputMode='decimal'
                        pattern='[0-9].,*'
                        placeholder="Fov" />

                    <span className='control-label mb-0'>Repeat Length</span>
                    <input required
                        value={this.props.Repeat}
                        name="Repeat"
                        onChange={(item) => this.props.onChange(item)}
                        className="form-control"
                        type="number"
                        inputMode='decimal'
                        pattern='[0-9].,*'
                        placeholder="Repeat" />
                </div>

                <div className="form-group row container-fluid pb-4">
                    <span className='control-label mb-0'>Machine Speed</span>
                    <input required
                        value={this.props.Speed}
                        name="Speed"
                        onChange={(item) => this.props.onChange(item)}
                        className="form-control"
                        type="number"
                        inputMode='decimal'
                        pattern='[0-9].,*'

                        autoComplete='150'
                        placeholder="Speed" />

                    <span className='control-label mb-0'>Encoder Wheel Revolution</span>
                    <input required
                        value={this.props.EncWheel}
                        name="EncWheel"
                        onChange={(item) => this.props.onChange(item)}
                        className="form-control"
                        type="number"
                        inputMode='decimal'
                        pattern='[0-9].,+'

                        placeholder="Encoder Wheel Revolution" />

                </div>
            </div>
        );
    }
}
export default Form;






