import { db } from './firebase';

// User API

export const doCreateUser = (id, displayName, email,isSales) =>
  db.ref(`users/${id}`).set({
    id,
    displayName,
    email,
    isSales
  });

export const onceGetUsers = () =>
  db.ref('users').once('value');

// Other db APIs ...
