import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import imageUS from '../App/Assets/US256.png'
import imageEUR from '../App/Assets/EUR256.png'
//import { PasswordForgetForm } from '../PasswordForget';
//import PasswordChangeForm from '../PasswordChange';
import withAuthorization from '../Session/withAuthorization';
import * as routes from '../../constants/routes';
import { firebase } from '../../firebase';
import * as fire from 'firebase';






const HomePage = ({authUser}) =>

 <div className="jumbotron" >
    <h3>Choose a calculator</h3>
    <div className="myBoxContainer"> 
   <div className="box">
    <a href={routes.CALC_MM}><img border="0" alt="W3Schools" src={imageEUR}  height="150" /></a>
    </div>
    <div className="box">
    
    <a href={routes.CALC_MM}><img border="0" alt="W3Schools" src={imageUS}  height="150" /></a>

    </div>
    </div>
    
    </div>
   
   

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
 
});

const authCondition = (authUser) => !!authUser;




export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps)
)(HomePage);