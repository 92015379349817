import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withAuthorization from '../Session/withAuthorization';
import { db } from '../../firebase';
//import * as firebase from 'firebase';

/*
VARS
*/
var camRes, docWidth, matSpeed, pxDensity, pxSize, lineRate, matRepeat, matRepeatOrigin;

camRes = 0;
docWidth = 0;
matSpeed = 0;
pxDensity = 0;
pxSize = 0;
lineRate = 0;
matRepeat = 0;

class Calcin extends Component {
  
  constructor(props) {
    super(props);
    this.state = { cam: '0' };
    this.state = { width: '0' };
    this.state = { speed: '0' };
    this.state = { repeat: '0' };
    this.state = { cam2 : '0' };

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConvert = this.handleConvert.bind(this); 

   
  }
  
  handleChange1(event) {
    this.setState({ cam: event.target.value}, () => camRes = parseInt(this.state.cam, 10));
  }
  handleChange2(event) {
    this.setState({ width: event.target.value }, () => docWidth = parseFloat(this.state.width, 10));
  }

  handleChange3(event) {
    this.setState({ speed: event.target.value }, () => matSpeed = parseFloat(this.state.speed, 10));
  }

  handleChange4(event) {
    this.setState({ repeat: event.target.value }, () => matRepeat = parseFloat(this.state.repeat, 10));
  }

 
  handleSubmit(event) {

    event.preventDefault();
    pxDensity = parseFloat(pxDensity, 10);
    pxSize = parseFloat(pxSize, 10);
    lineRate = parseFloat(lineRate, 10);


    pxDensity = (camRes / docWidth).toFixed(3);
    pxSize = ((1 / (pxDensity/25.4))).toFixed(6);
    lineRate = (matSpeed / 3.28084);
    lineRate = lineRate / 60;
    lineRate = (lineRate / (pxSize)).toFixed(3);
    matRepeatOrigin = matRepeat;
    matRepeatOrigin = (((matRepeatOrigin * 25.4)  / (((matSpeed/3.28084) * 1000) / 60)) * 1000).toFixed(3);

    this.setState({
      cam: 0,
      width: 0,
      speed: 0,
      repeat: 0
    }, () => this.setState({
      cam: camRes,
      width: docWidth,
      speed: matSpeed,
      repeat: matRepeat
    }));

    
    this.setState({ cam: event.target.value });
    this.setState({ width: event.target.value });
    this.setState({ speed: event.target.value });
    this.setState({ repeat: event.target.value });
    this.setState();

  }



  handleConvert(event)
  {
    event.preventDefault();
    var widthmm,speedmm,repeatmm; 
    widthmm = this.state.width; 
    speedmm = this.state.speed; 
    repeatmm = this.state.repeat; 

    docWidth = (widthmm / 25.4).toFixed(3); 
    matSpeed = (speedmm * 3.28084).toFixed(3); 
    matRepeat = (repeatmm / 25.4).toFixed(3); 

    this.setState({
      width : docWidth,
      speed : matSpeed,
      repeat : matRepeat
    }, () => this.setState({
      width : docWidth,
      speed : matSpeed,
      repeat : matRepeat
    })); 

  }
  

  
  componentDidMount() {
    const { onSetUsers } = this.props;

    db.onceGetUsers().then(snapshot =>
      onSetUsers(snapshot.val())
    );
   
  }

  render() {
    //const { users } = this.props;

    return (
      <div className="here">
        <div className="jumbotron">
        <h3> Camera Calculation</h3>
        <div className="myInfo" >
        <p> Please enter the required values in the text boxes ... if you don't have some of the parameters, please enter dummy values</p>
        </div>
          </div>
    
      <div className="container-fluid">
       
            <form className="form-group" onSubmit={this.handleSubmit}>

              
                
                  <label htmlFor="CamResolution">Resolution
                  <select className="form-control custom-select"  data-camera={this.state.cam2} value={this.state.cam} onChange={this.handleChange1.bind(this)} required>
                  <option value="">choose Camera</option>
                  <option ref="cameraOne" camera="16k"  value="16384">16k dalsa</option>
                  <option data-camera="CIS"value="7926">CIS - 600DPI FOV: 309</option>
                  <option value="14592">CIS - 600DPI FOV: 617</option>
                  <option value="21888">CIS - 600DPI FOV: 929</option>
                  <option value="8192">8k dalsa/basler</option>
                  <option value="4096">4k dalsa/basler</option>
                 <option value="2048">2k dalsa/basler</option>
                 <option value="1280">GE1200 - 1280x1024</option>
                 <option value="1600">GE1600 - 1600x1400</option>
                 <option value="2580">JAI GO 5Mpx - 2580x2048</option>
                 <option value="800">GR4 - 800x800 FOV: 35mm</option>
                 <option value="1024">GR2 - 1024x1024 FOV: 55mm</option>
                 <option value="1280">GR2X - 1280x1024 FOV: 70mm</option>
                 <option value="1280">GR6 - 1280x1024 FOV: 112mm</option>
                </select>
                  </label>
               
                <div className="row">
                <div className="col-4">
                  <label htmlFor="Width">width
                  <input className="form-control" placeholder="FOV" action="" type="text" value={this.state.width} onChange={this.handleChange2} required />
                  </label>
                </div>
                <div className="col-4">
                  <label htmlFor="Speed">Speed
                  <input className="form-control" placeholder="linear Speed" action="" type="text" value={this.state.speed} onChange={this.handleChange3} required />
                  </label>
                </div>

                <div className="col-4">
                  <label htmlFor="Repeat">Repeat
                  <input className="form-control" placeholder="distance between 2 triggers" action="" type="text" value={this.state.repeat} onChange={this.handleChange4} required />
                  </label>
                </div>

              </div>
              <input className="btn btn-success" action="" type="submit" value="Submit" onSubmit={this.handleSubmit} />
              <input id="btnConv" className="btn btn-info"  action=""  type="submit" value="to in" onClick={this.handleConvert} /> <i>click this button once if the inputed values are in mm and m/mim</i>
             

            </form>




            <table className="table table-sm table-bordered table-responsive">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Result</th>
                  <th>infos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"></th>
                  <td>pixel density</td>
                  <td className="bg-success">{pxDensity + " "} DPI</td>
                  <td className="bg-info">Resolution</td>
                  <td className="bg-info">{camRes + " px"}</td>
                  <td className="bg-info">width</td>
                  <td className="bg-info">{docWidth + " in"}</td>
                </tr>
                <tr>
                  <th scope="row"></th>
                  <td>line Rate</td>
                  <td className="bg-success">{lineRate + " "} khz</td>
                  <td className="bg-info">Speed</td>
                  <td className="bg-info">{matSpeed + " feet/min"}</td>
                  <td className="bg-info">repeat length</td>
                  <td className="bg-info">{matRepeat + " in"}</td>
                </tr>
                <tr>
                  <th scope="row"></th>
                  <td>Pixel Size</td>
                  <td className="bg-success">{(pxSize / 25.4).toFixed(6) + " "} in</td>
                </tr>
                <tr>
                  <th scope="row"></th>
                  <td>Repeat Time</td>
                  <td className="bg-success">{matRepeatOrigin + " "} ms</td>
                  <td>fps</td>
                  <td className="bg-success">{(1 / (matRepeatOrigin / 1000)).toFixed(3) + " "} fps</td>
                </tr>
                <tr>
                  <th scope="row"></th>
                  <th>Code Size</th>

                </tr>
                <tr>
                  <th scope="row"></th>
                  <td>1D</td>
                  <td className="bg-warning">{(3 / pxDensity).toFixed(5) + " "}in</td>
                  <td>2D</td>
                  <td className="bg-warning">{(5 / pxDensity).toFixed(5) + " "}in</td>
                  <td>OCR</td>
                  <td className="bg-warning">{(20 / pxDensity).toFixed(5) + " "}in</td>

                </tr>
                <tr>
                  <th scope="row"></th>
                  <td></td>
                </tr>

              </tbody>
            </table>
            <br />
            <br />
           
       </div>
      </div>
    );
  }
}

/*const UserList = ({ users }) =>
  <div>
    <h2>List of Usernames of Users</h2>
    <p>(Saved on Sign Up in Firebase Database)</p>

    {Object.keys(users).map(key =>
      <div key={key}>{users[key].username}</div>
    )}
  </div>*/

const mapStateToProps = (state) => ({
  users: state.userState.users,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUsers: (users) => dispatch({ type: 'USERS_SET', users }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(Calcin);
