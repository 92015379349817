import React, { Component } from 'react';
//import style from "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css";
//import mail from './maillogo.png';


class Footer extends Component {
  render() {
    return (
    
    	<footer className='jumbo'>
        <p>LISfactory.org All rights reserved 2022</p>
    
    	</footer>

    );
  }
}

export default Footer;
