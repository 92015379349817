import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import logo from './logo.png';
//import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';

const SignInPage = ({ history }) =>
  <div className="jumbotron" >
    <h3>Sign In</h3>
    <div className="myLogo" >
      <a href="./"><img src={logo} className="app-logo "alt="logo1" /></a>
      
      </div>
    <SignInForm history={history} />
    
   {/*} <SignUpLink />{*/}
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {email,password,} = this.state;

    const {history,} = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {email,password,error,} = this.state;

    const isInvalid =
      password === '' ||
      email === '';
  
    return (
     
   
      <div className="container">
      
      <br/>
      <form className="form-group" onSubmit={this.onSubmit}>
        <input className="form-control"
          value={email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        <input className="form-control"
          value={password}
          onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
          type="password"
          placeholder="Password"
        />
           <br/>
        <button className="btn btn-success" disabled={isInvalid} type="submit">
          Sign In
        </button>
        <br/>
        <PasswordForgetLink />
        { error && <p>{error.message}</p> }
      </form>
      <br/>
      </div>

     
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};
