//import React from 'react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import withAuthorization from '../Session/withAuthorization';




const AccountPage = ({ authUser }) =>

  <div className="jumbotron" >
    <h3>Account: {authUser.email}</h3>
    <h3>ID: {authUser.uid} </h3>
    <br/>
    <PasswordForgetForm />
    <br/>
    <PasswordChangeForm />
  
   
</div>
  

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps)
)(AccountPage);