const EurUnit = {
    sizeUnit: "mm",
    speedUnit: "m/min",
    pxUnit: "px/mm",
}

const UsUnit = {
    sizeUnit: "in",
    speedUnit: "ft/min",
    pxUnit: "dpi",
}



// Math for EUR/ISO
export function CalcResultEur(CamResolution, Fov, MachineSpeed, ImageRepeat,EncWheel,SSize,fl,CamDepth,camDist) {
    // Linerate Calculation
    let myCamResolution = parseFloat(CamResolution, 10);
    let myFov = parseFloat(Fov, 10);
    let myMachineSpeed = parseFloat(MachineSpeed, 10);
    let PxDensityFloat = (myCamResolution / myFov);
    let PxDensity = PxDensityFloat.toFixed(2);
    let dpi = (PxDensityFloat*25.4).toFixed(0);
    let PixelSizeFloat = (1 / PxDensity);
    let PixelSize = PixelSizeFloat.toFixed(4);
    let TimeForOnePixel = ((PixelSize * 60) / myMachineSpeed);
    let TimeForOnePixelStr = (TimeForOnePixel*1000).toFixed(3);
    let LineRate = (1 / TimeForOnePixel).toFixed(2);
    
    let EncPPR = ((EncWheel/PixelSizeFloat)/4).toFixed(0);

    //RepeatTime 
    let MyImageRepeat = parseFloat(ImageRepeat, 10);
    let RepeatTimeFloat = ((MyImageRepeat * 60) / myMachineSpeed);
    let RepeatTime = RepeatTimeFloat.toFixed(2);
    let Fps = (1000 / RepeatTime).toFixed(1);
    //BarCodeMinSize1D
    let BarCodeMinSize1D = (3 / PxDensity).toFixed(3);
    let BarCodeMinSize1DGrading = (BarCodeMinSize1D * 2).toFixed(3); 
    //BarCodeMinSize2D
    let BarCodeMinSize2D = (5 / PxDensity).toFixed(3);
    let BarCodeMinSize2DGrading = (BarCodeMinSize2D * 1.3).toFixed(3); 
    //OCRMinSize
    let OCRMinSize = (20 / PxDensity).toFixed(3);

    //PQProcTime
    let PQProcTimeFloat = (((CamResolution * (ImageRepeat*PxDensity))/1000000)*3.5);
    let PQProcTime = PQProcTimeFloat.toFixed(2);
    //PQMin Defect Size
    let PQMin = BarCodeMinSize1D;
    let CalcMessage = ""; 

    if (RepeatTimeFloat < (PQProcTimeFloat + 10) ){
        CalcMessage = "Machine Speed should be slower due to PQ Processing time. you are short by : " + (RepeatTimeFloat-(PQProcTimeFloat+10)).toFixed(2)+"ms";
        
    }else {
        CalcMessage = "Processing Time Ok - Check LineRate for Camera Speed"; 
        
    }

    let camDistance = 0; 
   
    if (camDist === 0)
    {
        
        let B = Fov/SSize;
        camDistance = ((fl*(2+B+(1/B))-1.85)-CamDepth).toFixed(2);
    } else {camDistance = camDist;}
     
    //let camDistance = ((fl*(B+1))+fl - CamDepth).toFixed(2); 
    //console.log(fl);
    

    //return CalcResult function will return an Object of Results to be integrated inside listOfCalcs
    return {
        CamResolution,
        Fov,
        MachineSpeed,
        ImageRepeat,
        TimeForOnePixelStr,
        LineRate,
        RepeatTime,
        PxDensity,
        PixelSize,
        BarCodeMinSize1D,
        BarCodeMinSize2D,
        OCRMinSize,
        BarCodeMinSize1DGrading,
        BarCodeMinSize2DGrading,
        PQProcTime,
        Fps,
        PQMin,
        dpi,
        CalcMessage,
        EncPPR,
        EncWheel,
        camDistance,
        SSize,
        fl,
        ...EurUnit
    };
}

// Math For US/ Inchs 
export function CalcResultUs(CamResolution, Fov, MachineSpeed, ImageRepeat,EncWheel,SSize,fl,CamDepth, camDist) {
    // Linerate Calculation
    let myCamResolution = parseFloat(CamResolution, 10);
    let myFov = parseFloat(Fov, 10);
    let myMachineSpeed = parseFloat(MachineSpeed, 10);
    let PxDensity = (myCamResolution / myFov).toFixed(2);
    let PixelSize = (1 / (PxDensity/25.4)).toFixed(6);
    let TimeForOnePixel = ((PixelSize * 60) / (myMachineSpeed / 3.28084));
    let TimeForOnePixelStr = (TimeForOnePixel*1000).toFixed(3);
    let LineRate = (1 / TimeForOnePixel).toFixed(2);

    //RepeatTime 
    let MyImageRepeat = parseFloat(ImageRepeat, 10);
    let RepeatTimeFloat = ((MyImageRepeat * 25.4 * 60) / (myMachineSpeed/3.28084));
    let RepeatTime = RepeatTimeFloat.toFixed(2);
    let Fps = (1000 / RepeatTime).toFixed(1);

    //Pixel Size to in 
    let PixelSizeFloat = (PixelSize/25.4);
    PixelSize = PixelSizeFloat.toFixed(4);
    let EncPPR = (((EncWheel)/PixelSizeFloat)/4).toFixed(0);

    //BarCodeMinSize1D
    let BarCodeMinSize1D = (3 / PxDensity).toFixed(3);
    let BarCodeMinSize1DGrading = (BarCodeMinSize1D * 2).toFixed(3);
    //BarCodeMinSize2D
    let BarCodeMinSize2D = (5 / PxDensity).toFixed(3);
    let BarCodeMinSize2DGrading = (BarCodeMinSize2D * 1.3).toFixed(3); 
    //OCRMinSize
    let OCRMinSize = (20 / PxDensity).toFixed(3);

     //PQProcTime
     let PQProcTimeFloat = (((CamResolution * (ImageRepeat*PxDensity))/1000000)*3.5);
     let PQProcTime = PQProcTimeFloat.toFixed(2);
     //PQMin Defect Size
     let PQMin = BarCodeMinSize1D;
     let CalcMessage = ""; 

     if (RepeatTimeFloat < (PQProcTimeFloat + 10) ){
         CalcMessage = "Machine Speed should be slower due to PQ Processing time. you are short by : " + (RepeatTimeFloat-(PQProcTimeFloat+10)).toFixed(2)+"ms";
         
     }else {
         CalcMessage = "Processing Time Ok - Check LineRate for Camera Speed"; 
         
     }
    
     let camDistance =0; 

   if (camDist ===0)
   {
    let B = (Fov/SSize);
    let camDistance_med = ((fl*(2+B+(1/B))-(1.85))-CamDepth);
    camDistance = (camDistance_med / 25.4).toFixed(2); 

   } else {camDistance = (camDist/25.4).toFixed(3);}
    
   
    //return CalcResult function will return an Object of Results to be integrated inside listOfCalcs
    return {
        CamResolution,
        Fov,
        MachineSpeed,
        TimeForOnePixelStr,
        ImageRepeat,
        LineRate,
        RepeatTime,
        PxDensity,
        PixelSize,
        BarCodeMinSize1D,
        BarCodeMinSize2D,
        OCRMinSize,
        BarCodeMinSize1DGrading,
        BarCodeMinSize2DGrading,
        PQProcTime,
        Fps,
        PQMin,
        CalcMessage,
        EncPPR,
        EncWheel,
        camDistance,
        SSize,
        fl,
        ...UsUnit
    };
}




