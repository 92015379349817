import React, { Component } from 'react';
import CamCalc from './CamCalc';

//import Linescan from './linescan1.jpg';
//import Gr from './GRCam.jpg';
//import Ge from './GECamera.jpg';


import { connect } from 'react-redux';
import { compose } from 'recompose';

import withAuthorization from '../Session/withAuthorization';
import { db } from '../../firebase';
//import * as firebase from 'firebase';



class CamCalculationIndex extends Component {
  constructor(props) {
    super(props);
    
    this.state = { listOfCalcs: [] };
    this.deletePost = this.deletePost.bind(this);
  }
  

  myCallBack = (dataFromCalc) => {
    this.setState({ listOfCalcs: dataFromCalc });

  }

  deletePost(e, b) {
    this.state.listOfCalcs.splice(e.target.id, 1);
    this.setState(this.state);

  }


  componentDidMount() {
    
    const { onSetUsers } = this.props;

  db.onceGetUsers().then(snapshot =>
      onSetUsers(snapshot.val())
    );
  
  }

  render() {
    
    const myList = this.state.listOfCalcs.map( (item, index) => {
        return <div className="jumbo" key={index}>
          <div className="jumboTitle"><button id={index} 
                                              onClick={(idx, e) => this.deletePost(idx, e)}
                                              className="btn btn-outline-danger btn-sm">x</button>
                                              <div className="jumboTitleContent"> 
                                              {`${item.select} • FOV: ${item.Fov + item.sizeUnit} • Repeat: ${item.ImageRepeat + item.sizeUnit} • Speed: ${item.MachineSpeed + item.speedUnit} • ${item.Fps + "fps"}`}</div> </div>
          <div className="jumboContent">
            <div className="row">
            
              <div className='col'>
                <div><u>Resolution:</u> {item.CamResolution}px</div>
                <div><u>Pixel Density:</u> {item.PxDensity + item.pxUnit} ({item.dpi}dpi)</div>
                <div><u>Encoder PPR:</u> {item.EncPPR} ppr</div>
                <div><u>pixel Size:</u> {item.PixelSize + item.sizeUnit}</div>
                <div><u>Repeat Time:</u> {item.RepeatTime}ms</div>
                <div><u>PQ Proc Time:</u> {item.PQProcTime}ms</div>
                <div><u>PQ min Defect Size:</u> {item.PQMin + item.sizeUnit}</div>
                <div><u>Approx Camera Master Distance:</u> {item.camDistance + item.sizeUnit}</div>
              </div>

              <div className='col'>
                <div><u>Line Rate:</u> {item.LineRate}khz</div>
                <div><u>Exp time: </u>{item.TimeForOnePixelStr}us</div>
                <div><u>1D min Size:</u> {item.BarCodeMinSize1D+ item.sizeUnit} - <i>(Read)</i></div>
                <div><u>2D min Size:</u> {item.BarCodeMinSize2D+ item.sizeUnit} - <i>(Read)</i></div>
                <div><u>Ocr min Height:</u> {item.OCRMinSize+ item.sizeUnit}</div>
                <div><u>1D Grading:</u> {item.BarCodeMinSize1DGrading + item.sizeUnit} - <i>(Grade)</i></div>
                <div><u>2D Grading:</u> {item.BarCodeMinSize2DGrading + item.sizeUnit} - <i>(Grade)</i></div>
                <div><u>Sensor size</u> {item.SSize + item.sizeUnit}</div>
                <div><u><b>B=</b></u> {(item.SSize/item.Fov).toFixed(4)} - <u><b>B'=</b></u> {(item.Fov/item.SSize).toFixed(4)}</div>
               

              </div>
             
            </div>
           
          </div>
        
          <div>{item.CalcMessage}</div>
          <div><i><b>Please Confirm these results with a Lake image Engineer with the specification of the application</b></i></div>
          
          <hr />
         
         
        </div>
      });
    return (
      <div className="">
       <h3>Camera Calculation </h3>
        <div className="input-container">
          
          <CamCalc callBackFromParent={this.myCallBack} />
        </div>
        <hr />
        <div className="">
          {myList}
          
        </div>
       
        
       
      </div>
    );
  }
}

/*const UserList = ({ users }) =>
  <div>
    {console.log(users)}
    <h2>List of Usernames of Users</h2>
    <p>(Saved on Sign Up in Firebase Database)</p>

    {Object.keys(users).map(key =>
      <div key={key}>{users[key].displayName}</div>
    )}
  </div>*/

const mapStateToProps = (state) => ({
  users: state.userState.users,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUsers: (users) => dispatch({ type: 'USERS_SET', users }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(CamCalculationIndex);

