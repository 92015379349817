import React, { Component } from 'react';
import Form from './presentation'
import '../../App.css';
import { CameraListInit } from './constant'
import _ from 'lodash';
import { CalcResultEur, CalcResultUs } from './logic';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { imgData } from './base64image';
import { firebase } from '../../firebase';
import * as fire from 'firebase';





/*const INITIAL_STATE = {
    calculating: false,
    select: "",
    Fov: "",
    Speed: "",
    Repeat: "",
    PQProcTime:"",
    message:"",
    EncWheel:"200"
    
};*/

class CamCalc extends Component {

    constructor(props) {
        super(props);



        this.state = {
            calculating: false,
            select: "",
            Fov: "",
            Speed: "",
            Repeat: "",
            pxDensity: 0,
            EncWheel: "",
            Lens: "",
            camDist: 0,
            isEuro: true,
            isPdfHidden: true,
            Read: "",
            authUserUid: firebase.auth.currentUser.uid,
            DBcamList: {},
            dbUser: [],
            NewListFromIndex: []
        };
        var starCountRef = fire.database().ref('users/' + this.state.authUserUid);
        starCountRef.on('value', (snapshot) => {
            const data = snapshot.val();

            this.setState({ dbUser: data });




        });

        var GetCamlistDB = fire.database().ref('Camlist/');
        GetCamlistDB.on('value', (snapshot) => {
            const CamlistFromDB = snapshot.val();


            this.setState({ DBcamList: CamlistFromDB });
        })

        this.HandleChange = this.HandleChange.bind(this);
        this.HandleRadioChange = this.HandleRadioChange.bind(this);
        this.Calculate = this.Calculate.bind(this);
        this.toPdf = this.toPdf.bind(this);


    }

    //Handle Change from Inputs and save them in state. 
    HandleChange(e) {
        _.map(this.state.DBcamList,
            p => {

                if (p.name === e.target.value) {
                    if (this.state.isEuro) {
                        this.setState({
                            Resolution: p.Resolution,
                            Fov: p.FOV,
                            SensorSize: p.sensorSize,
                            Lens: p.Lens,
                            camDepth: p.camDepth,
                            camDist: p.CamDistance,
                            EncWheel: p.encwheel,
                            Read: p.Read




                        });
                    } else {
                        this.setState({
                            Resolution: p.Resolution,
                            Fov: (p.FOV / 25.4).toFixed(2),
                            SensorSize: (p.sensorSize / 25.4).toFixed(6),
                            Lens: p.Lens,
                            camDepth: p.camDepth,
                            camDist: p.CamDistance,
                            EncWheel: (p.encwheel / 25.4),
                            Read: p.Read


                        });
                    }
                }
                return null
            });

        this.setState({
            [e.target.name]: e.target.value
        });
    }

    HandleRadioChange(e) {
        if (e.target.id === 'EuroUnits') { return this.setState({ isEuro: true, select: "", Fov: "", Encwheel: null }); }

        else this.setState({ isEuro: false, select: "", Fov: "", Encwheel: null });


    }

    toPdf() {
        const doc = new jsPDF('p', 'pt', 'a4');
        let list = {};

        list = this.state.NewListFromIndex;
        var length = list.length;
        var pageCounter = 1;

        if (length === 0) {
            return;
        }

        list.forEach(item => {

            doc.setTextColor(175);
            doc.text(item.select, 200, 50);
            doc.text("FOV:" + item.Fov + item.sizeUnit, 275, 70);
            doc.text("Application Data", 40, 100);

            doc.autoTable({
                head: [['Field Of View', 'Machine Speed', 'Repeat length', 'Encoder Wheel', 'Frame Rate', 'Encoder PPR']],
                body: [
                    [item.Fov + item.sizeUnit,
                    item.MachineSpeed + item.speedUnit,
                    item.ImageRepeat + item.sizeUnit,
                    item.EncWheel + item.sizeUnit,
                    item.Fps + "fps",
                    item.EncPPR]],
                startY: 110
            });

            doc.text("Camera Data", 40, 180);
            doc.autoTable({
                head: [['Resolution', 'Pixel Density', 'Pixel Size', 'LineRate', 'Exposure Time']],
                body: [
                    [item.CamResolution + 'px', item.PxDensity + item.pxUnit, item.PixelSize + item.sizeUnit, item.LineRate + 'khz', item.TimeForOnePixelStr + 'us'],

                ],
                startY: 190,

            });
            doc.text("Inspection Specification", 40, 260);
            doc.autoTable({
                head: [['Reading 1D XDim', 'Reading 2D XDim', 'Grading 1D XDim', 'Grading 2D XDim', 'OCR Height min', 'PQ Min Defect']],
                body: [
                    [item.BarCodeMinSize1D + item.sizeUnit, item.BarCodeMinSize2D + item.sizeUnit, item.BarCodeMinSize1DGrading + item.sizeUnit, item.BarCodeMinSize2DGrading + item.sizeUnit, item.OCRMinSize + item.sizeUnit, item.PQMin + item.sizeUnit],

                ],
                startY: 270,

            });
            doc.text("Performance", 40, 430);
            doc.autoTable({
                head: [['Repeat Time', 'PQ Processing time', 'Message']],
                body: [
                    [item.RepeatTime + 'ms', item.PQProcTime + 'ms', item.CalcMessage],

                ],
                startY: 440,

            });
            doc.addImage(imgData.xdim, 'JPG', 200, 340, 175, 65);
            doc.addImage(imgData.camDist, 'JPG', 85, 510, 450, 278);
            doc.text(item.camDistance + item.sizeUnit, 155, 615);
            doc.setFillColor(230);
            doc.rect(0, 800, 600, 60, 'F')
            doc.text(pageCounter + '/' + length, 550, 825);
            if ((length - pageCounter) !== 0) {
                doc.addPage();

            }

            pageCounter = pageCounter + 1;
        });
        var today = new Date()
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        // doc.text("Last Page", 100, 100);
        for (var i = 0; i <= length; i++) {

            doc.setPage(i);

            doc.addImage(imgData.logo, 'PNG', 20, 25, 86, 41);
            doc.setFontSize(8);
            doc.text("Generated By: " + this.state.dbUser.displayName + " on " + date, 20, 825);
        }
        doc.save(date + '.pdf');
    }


    //Calculating Everything and push it. 
    Calculate(e) {

        let select = this.state.select;
        let resultGeneral = {};
        if (this.state.isEuro) {
            resultGeneral = CalcResultEur(this.state.Resolution,
                this.state.Fov,
                this.state.Speed,
                this.state.Repeat,
                this.state.EncWheel,
                this.state.SensorSize,
                this.state.Lens,
                this.state.camDepth,
                this.state.camDist
            );
        }
        else {
            resultGeneral = CalcResultUs(this.state.Resolution,
                this.state.Fov,
                this.state.Speed,
                this.state.Repeat,
                this.state.EncWheel,
                this.state.SensorSize,
                this.state.Lens,
                this.state.camDepth,
                this.state.camDist
            );
        }

        this.setState({
            calculating: true,
        }, () => {
            this.state.NewListFromIndex.push({
                select,
                ...resultGeneral,

            });


            this.props.callBackFromParent(this.state.NewListFromIndex);

            //this.setState(INITIAL_STATE);
        });

        this.setState({ isPdfHidden: false })


    }

    render() {

        return (
            <div>
                <fieldset className="form-group">

                    <div className="form-check">
                        <label className="form-check-label">
                            <input onChange={this.HandleRadioChange} type="radio" className="form-check-input" name="OptionRadio" id="EuroUnits" value="" defaultChecked />
                            EUR • mm • m/min • px/mm
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input onChange={this.HandleRadioChange} type="radio" className="form-check-input" name="OptionRadio" id="UsUnits" value="" />
                            US • in • ft/min • dpi
                        </label>
                    </div>

                </fieldset>
                <Form

                    select={this.state.select}
                    Fov={this.state.Fov}
                    Speed={this.state.Speed}
                    Repeat={this.state.Repeat}
                    EncWheel={this.state.EncWheel}
                    Lens={this.state.Lens}
                    Read={this.state.Read}
                    Resolution={this.state.Resolution}
                    onChange={(e) => this.HandleChange(e)} />

                <div className='jumbo'>



                    <button disabled className='btn btn-outline-primary mb-2 d-inline'>{

                        (this.state.Resolution / this.state.Fov).toFixed(2)}
                        {this.state.isEuro ? " px/mm " : " dpi "}

                    </button>
                    <button disabled className='btn btn-outline-primary mb-2 d-inline'>
                        {this.state.isEuro ?
                            ((((this.state.Resolution / this.state.Fov) * this.state.Speed) / 60).toFixed(2) + " khz      ")
                            : (1 / (((1 / ((this.state.Resolution / this.state.Fov) / 25.4)) * 60) / (this.state.Speed / 3.28084))).toFixed(2) + "khz    "}
                    </button>




                    <button disabled className='btn btn-outline-primary mb-2 d-inline'>
                        {
                            this.state.isEuro ?
                                (1 / (((this.state.Resolution / this.state.Fov) * this.state.Speed) / 60) * 1000).toFixed(2) + " μs "
                                : ((((1 / ((this.state.Resolution / this.state.Fov) / 25.4)) * 60) / (this.state.Speed / 3.28084)) * 1000).toFixed(2) + " μs "}

                    </button>

                    <button disabled className='btn btn-outline-primary mb-2 d-inline'>

                        {this.state.isEuro ?
                            ((this.state.EncWheel / (this.state.Fov / this.state.Resolution)) / 4).toFixed(0)
                            : ((this.state.EncWheel / ((this.state.Fov * 25.4) / (this.state.Resolution * 25.4))) / 4).toFixed(0)
                        }  ppr</button>



                    <button
                        onClick={() => this.Calculate()}
                        className="btn btn-info jumbo">Calculate
                    </button>
                    <button hidden={this.state.isPdfHidden} onClick={() => this.toPdf()} className="btn btn-primary">Export PDF</button>

                </div>
            </div>
        );
    }
}

export default CamCalc;
