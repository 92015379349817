import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import './Assets/css/default.min.css'; 
import Navigation from '../Navigation';
import Footer from '../Footer';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import withAuthentication from '../Session/withAuthentication';
import Calc from '../Calcmm';
import Calcin from '../Calcin';

import * as routes from '../../constants/routes';

import './index.css';

const App = () =>
  <Router>
    <div className="app">

      <Navigation />
  
      <hr/>

      <Route exact path={routes.LANDING} component={() => <HomePage />} />
      <Route exact path={routes.CALC_MM} component={() => <Calc />} />
      <Route exact path={routes.CALC_IN} component={() => <Calcin />} />
      <Route exact path={routes.SIGN_UP} component={() => <SignUpPage />} />
      <Route exact path={routes.SIGN_IN} component={() => <SignInPage />} />
      <Route exact path={routes.PASSWORD_FORGET} component={() => <PasswordForgetPage />} />
      <Route exact path={routes.HOME} component={() => <HomePage />} />
      <Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
      

      <hr/>

      <Footer />

      </div>
  </Router>

export default withAuthentication(App);