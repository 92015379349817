import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import logo1 from './logo.png';

import SignOutButton from '../SignOut';
import * as routes from '../../constants/routes';

const Navigation = ({ authUser }) =>
  <div>
    { authUser
        ? <NavigationAuth />
        : <NavigationNonAuth />
    }
  </div>

const NavigationAuth = () =>
  <header>
    <div className = "logo">
    		<a href="./"><img src={logo1} className="app-logo1 "alt="logo1" /></a>
    		</div>
  <nav>
  <ul>
    
    <li><Link to={routes.HOME}>Home</Link></li>
    <li><Link to={routes.ACCOUNT}>Account</Link></li>
    <li><SignOutButton /></li>
  </ul>
  </nav>
  </header>
const NavigationNonAuth = () =>
 <header>
    <div className = "logo">
    		<a href="./"><img src={logo1} className="app-logo1 "alt="logo1" size="50"/></a>
    		</div>
   <nav>
 <ul>
    
    <li><Link to={routes.SIGN_IN}>Sign In</Link></li>
  </ul>
  </nav>
  </header>

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);
